import TrustedByslide from 'components/TrustedByslide'
import React, { FC, useEffect, useState } from 'react'
import Walletslide from 'components/WalletSlide'
import LatestReviewSlide from 'components/LatestReviewSlide'
import Link from 'next/link'
import Image from 'next/image'
import toast from 'helpers/toast'
import { ToastContainer } from 'react-toastify'
import { GlobalContext } from 'context/globalContext'
import ReactMultiSelectCheckboxes from 'react-multiselect-checkboxes'
import debounce from 'lodash.debounce'
import CircularProgress from '@mui/material/CircularProgress'
import Backdrop from '@mui/material/Backdrop'
import { Container } from 'react-bootstrap'
import { useRouter } from 'next/router'
// import {
//   getContractTypeAsync,
//   getLanguagesAsync,
// } from 'actions/post-job.action'
import { useSession } from 'next-auth/react'
import {
  checkCompanyCreated,
  getCompanies,
  getContractTypeAsync,
  getCountForHomepageAsync,
  getFilterValuesAsync,
  getLanguagesAsync,
  getLocationsAsync,
  paymentSuccess,
  subscribeAsync,
} from 'services/webservice/api'
import SignupFooter from 'layout/LandingPageLayout/SignupFooter'
import coverImage from '../../../public/assets/images/internalcover.png'

const HomePage = (): JSX.Element => {
  const { isLoggedIn }: any = React.useContext(GlobalContext)
  const { data: session }: any = useSession()

  // const [show, setShow] = useState(true);
  const router = useRouter()
  const { success } = router.query

  const [tab, setTab] = useState()

  // For search Filter
  const [searchText, setSearchText] = useState<string>('')
  const [jobLocation, setJobLocation] = useState<string[]>([])
  const [contract, setContract] = useState<string[]>([])
  const [language, setLanguage] = useState<string[]>([])
  const [tags, setTags] = useState<string[]>([])

  const [limit, setLimit] = useState(10)
  const [skip, setSkip] = useState(0)
  const [totalPage, setTotalPage] = useState(0)
  const [toggle, setToggle] = useState(false)
  const [loading, setLoading] = useState(false)

  // For Searchbar Dropdown
  const [location, setLocation] = useState<any>([])
  const [languageName, setLanguageName] = useState<any>([])
  const [contractType, setContractType] = useState<any>([])

  const [companyList, setCompanyList] = useState<any[]>([]) // For Listing Company's Jobs

  const [subscribeEmail, setSubscribeEmail] = useState('') // For subscribe email
  const [subscribeSuccess, setSubscribeSuccess] = useState(false)
  const [subscribeError, setSubscribeError] = useState(false)
  const [subscribeEmpty, setSubscribeEmpty] = useState(false)
  const [subscriberExists, setSubscriberExists] = useState(false)

  const [subscribeLoading, setSubscribeLoading] = useState(false)

  const [email, setEmail] = useState('') // For redirect to sign-up page

  // For Filter Value
  const [contractFilter, setContractFilter] = useState<any[]>([])
  const [favouriteLanguage, setFavouriteLanguage] = useState<any[]>([])
  const [unFavouriteLanguage, setUnFavouriteLanguage] = useState<any[]>([])
  const [favouriteLocation, setFavouriteLocation] = useState<any[]>([])
  const [unFavouriteLocation, setUnFavouriteLocation] = useState<any[]>([])
  const [tagsFilter, setTagsFilter] = useState<any[]>([]) // For Listing tags

  const [countForJobs, setCountForJobs] = useState<any>({
    job: '',
    applications: '',
    companies: '',
    salaries: '',
  }) // For get Count of Jobs & application

  const [changeContractValue, setChangeContractValue] = useState<any>([])
  const [changeLocationValue, setChangeLocationValue] = useState<any>([])
  const [changeLanguageValue, setChangeLanguageValue] = useState<any>([])

  // const [show, setShow] = useState(false)
  // const handleClose = () => setShow(false)
  // const handleShow = () => setShow(true)
  interface ApiBody {
    search: string
    jobLocation: string[]
    contractType: string[]
    jobLanguage: string[]
    tags: string[]
  }
  const searchBody: ApiBody = {
    search: searchText,
    jobLocation,
    contractType: contract,
    jobLanguage: language,
    tags,
  }

  // For jobs-card listing
  const getAllCompanies = async () => {
    setLoading(true)
    const response = await getCompanies(limit, skip, searchBody)
    if (response.responseCode === 200) {
      setCompanyList([...companyList, ...response.responseData.records])
      setTotalPage(response.responseData.totalPage)
      setLoading(false)
    } else {
      setLoading(false)
      toast.error(response.responseMessage)
    }
  }
  const handleChangeSeemore = () => {
    setSkip(skip + 1)
  }
  const handleSearch = debounce((e) => {
    setSkip(0)
    setCompanyList([])
    setSearchText(e.target.value)
  }, 1000)

  // For getCount numbers
  const getAllCount = async () => {
    const response = await getCountForHomepageAsync()
    if (response.responseCode === 200) {
      setCountForJobs({
        job: response.responseData.job,
        applications: response.responseData.applicant,
        companies: response.responseData.company,
        salaries: '1000+',
      })
    } else {
      toast.error(response.responseMessage)
    }
  }

  function capitalName(text) {
    return text.charAt(0).toUpperCase() + text.slice(1)
  }

  // For listing side filters
  const getFilterValues = async () => {
    const response = await getFilterValuesAsync()

    if (response.responseCode === 200) {
      const favLan: any = []
      const unFavLan: any = []
      const favLocation: any = []
      const unFavLocation: any = []
      let tags: any = []
      setContractFilter(response.responseData.contractType)

      response.responseData.tags &&
        response.responseData.tags.forEach((cd) => {
          return tags.push(capitalName(cd))
        })
      setTagsFilter(tags)

      response.responseData &&
        response.responseData.languages.forEach((cd) => {
          return cd.favourite === true ? favLan.push(cd) : unFavLan.push(cd)
        })
      setFavouriteLanguage(favLan)
      setUnFavouriteLanguage(unFavLan)

      response.responseData &&
        response.responseData.locations.forEach((cd) => {
          return cd.favourite === true
            ? favLocation.push(cd)
            : unFavLocation.push(cd)
        })

      setFavouriteLocation(favLocation)
      setUnFavouriteLocation(unFavLocation)
    }
  }

  const handleShowOtherLanguage = () => {
    setFavouriteLanguage([...favouriteLanguage, ...unFavouriteLanguage])
    setUnFavouriteLanguage([])
  }

  const handleShowOtherLocation = () => {
    setFavouriteLocation([...favouriteLocation, ...unFavouriteLocation])
    setUnFavouriteLocation([])
  }

  // For listing location on searchbar
  const getAllLocation = async () => {
    const obj: any = []
    const response = await getLocationsAsync()

    if (response.responseCode === 200) {
      response.responseData &&
        response.responseData.forEach(
          (cd: { _id: string; locationName: string }) => {
            obj.push({
              value: cd._id,
              label: cd.locationName,
            })
          }
        )
      setLocation(obj)
    }
  }
  // For listing language on searchbar
  const getLanguageDetails = async () => {
    const obj: any = []
    const response = await getLanguagesAsync()
    if (response.responseCode === 200) {
      response.responseData &&
        response.responseData.forEach(
          (cd: { _id: string; languageName: string }) => {
            obj.push({
              value: cd._id,
              label: cd.languageName,
            })
          }
        )
      setLanguageName(obj)
    }
  }

  // For listing contract on searchbar
  const getAllContract = async () => {
    let obj: any = []
    const response = await getContractTypeAsync()
    if (response.responseCode === 200) {
      response.responseData &&
        response.responseData.values.forEach((cd) => {
          obj.push({
            value: cd,
            label: cd,
          })
        })
      setContractType(obj)
    }
  }

  function toTimestamp(createdAt: string | number | Date) {
    var date = new Date(createdAt).toLocaleDateString('en-GB', {
      day: '2-digit',
      month: 'long',
      year: 'numeric',
    })
    return date
  }

  function validEmail(email) {
    /* eslint-disable-next-line */
    var pattern =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    return new RegExp(pattern).test(email)
  }

  const handleSubscribe = async () => {
    if (subscribeEmail === '') {
      setSubscribeEmpty(true)
    } else {
      const isValidEmail = validEmail(subscribeEmail)
      if (isValidEmail) {
        setSubscribeLoading(true)
        const response = await subscribeAsync({ email: subscribeEmail })
        if (response.responseCode === 200) {
          setSubscribeLoading(false)
          setSubscribeSuccess(true)
          setSubscribeEmail('')
        } else {
          setSubscribeLoading(false)
          setSubscriberExists(true)
        }
      } else {
        setSubscribeEmpty(true)
        setSubscribeError(true)
      }
    }
  }

  const handleChangeTags = (e) => {
    setSkip(0)
    setCompanyList([])
    const { value, checked } = e.target
    if (!checked) {
      setTags(tags.filter((item) => item !== value))
    } else {
      setTags([...tags, value])
    }
  }

  const handleChangeContract = (e) => {
    setSkip(0)
    setCompanyList([])
    const { value, checked } = e.target
    if (!checked) {
      setContract(contract.filter((item) => item !== value))
      setChangeContractValue(
        changeContractValue.filter((item) => item.value !== value)
      )
    } else {
      setContract([...contract, value])
      contractType &&
        contractType.forEach((cd) => {
          if (cd.value === value) {
            setChangeContractValue([...changeContractValue, cd])
          }
        })
    }
  }

  const handleChangeLanguage = (e) => {
    setSkip(0)
    setCompanyList([])
    const { id, checked } = e.target

    if (!checked) {
      setLanguage(language.filter((sId) => sId !== id))
      setChangeLanguageValue(
        changeLanguageValue.filter((item) => item.value !== id)
      )
    } else {
      setLanguage([...language, id])
      languageName &&
        languageName.forEach((cd) => {
          if (cd.value === id) {
            setChangeLanguageValue([...changeLanguageValue, cd])
          }
        })
    }
  }

  const handleChangeLocation = (e) => {
    setSkip(0)
    setCompanyList([])
    const { id, checked } = e.target

    if (!checked) {
      setJobLocation(jobLocation.filter((sId) => sId !== id))
      setChangeLocationValue(
        changeLocationValue.filter((item) => item.value !== id)
      )
    } else {
      setJobLocation([...jobLocation, id])
      location &&
        location.forEach((cd) => {
          if (cd.value === id) {
            setChangeLocationValue([...changeLocationValue, cd])
          }
        })
    }
  }

  const handleChangepost = (e, companyId, jobId) => {
    e.preventDefault()
    router.push(`/CompaniesPage?id=${companyId}&jobId=${jobId}`)
  }
  const handleClickShare = (e) => {
    e.stopPropagation()
  }

  const handleClickMenu = (e) => {
    e.stopPropagation()
  }
  const handleManageCreateCompany = async () => {
    setLoading(true)
    if (isLoggedIn) {
      const checkIsCompanyCreate = await checkCompanyCreated(session.user.id)
      if (checkIsCompanyCreate.responseCode === 200) {
        setLoading(false)
        router.push('/CreateCompany')
      } else {
        setLoading(false)
        router.push(
          `/CreateCompany?editCompanyProfile=${checkIsCompanyCreate.responseData.companyId}`
        )
      }
    } else {
      setLoading(false)
      router.push('/login')
    }
  }

  // not need now 
  
  // const payment = async (sessionId) => {
  //   const paymentStatus = await paymentSuccess(sessionId)
  //   console.log("paymentStatus",paymentStatus);
  //   if (paymentStatus.status === 200) {
  //     toast.success(paymentStatus.message)
  //   }
  //   if (paymentStatus.status === 402) {
  //     toast.error(paymentStatus.message)
  //   }
  //   if (paymentStatus.status === 500) {
  //     toast.error(paymentStatus.message)
  //   }
  // }

  useEffect(() => {
    if (success === 'true') {
      toast.success('Your payment was successful! Thank you for your purchase')
      setTimeout(() => {
        router.replace('/')
      }, 1000)
    }
  }, [router])

  useEffect(() => {
    window.history.scrollRestoration = 'manual'
  }, [])

  useEffect(() => {
    setTimeout(() => {
      setSubscribeSuccess(false)
    }, 5000)
  }, [subscribeSuccess])

  useEffect(() => {
    if (subscriberExists) {
      setTimeout(() => {
        setSubscriberExists(false)
      }, 5000)
    }
  }, [subscriberExists])

  useEffect(() => {
    if (subscribeError) {
      setTimeout(() => {
        setSubscribeError(false)
      }, 5000)
    }
  }, [subscribeError])

  useEffect(() => {
    if (subscribeEmpty) {
      setTimeout(() => {
        setSubscribeEmpty(false)
      }, 5000)
    }
  }, [subscribeEmpty])

  useEffect(() => {
    getAllLocation()
    getAllContract()
    getLanguageDetails()
    getAllCount()
    getFilterValues()
  }, [])

  useEffect(() => {
    getAllCompanies()
  }, [skip, limit, searchText, language, jobLocation, contract, tags])

  useEffect(() => {
    setSkip(0)
    setCompanyList([])
    let contractArray: any = []
    changeContractValue &&
      changeContractValue.forEach((cd) => {
        contractArray.push(cd.value)
      })
    setContract(contractArray)
  }, [changeContractValue])

  useEffect(() => {
    setSkip(0)
    setCompanyList([])
    let locationArray: any = []
    changeLocationValue &&
      changeLocationValue.forEach((cd) => {
        locationArray.push(cd.value)
      })
    setJobLocation(locationArray)
  }, [changeLocationValue])

  useEffect(() => {
    setSkip(0)
    setCompanyList([])
    let languageArray: any = []
    changeLanguageValue &&
      changeLanguageValue.forEach((cd) => {
        languageArray.push(cd.value)
      })
    setLanguage(languageArray)
  }, [changeLanguageValue])
  return (
    <>
      <ToastContainer />
      <div className="homepage homepage-main">
        <section>
          <div className="container home-page">
            <div className="row align-items-center text-white">
              <div className="col-12 text-center sw-background-color-none sw-border-style-none sw-border-width-none sw-border-color-000000 sw-border-radius-none sw-box-shadow-none sw-padding-top-none sw-padding-bottom-none">
                <h2 className="home_title">Find Your Web3 Job with 0xCareer</h2>
                <p className="home_sub_title">
                  <span className="opacity-70">Browse hand-curated </span>
                  <Link href={' '}>Web3 Career</Link>
                  <span className="opacity-70">
                    {' '}
                    opportunities in the most recruited job categories:
                    Blockchain, Cryptocurrencies, NFTs, Metaverse
                  </span>
                  🌈
                </p>
                <div className="fields-parent-container d-flex justify-content-center flex-wrap mx-sm-3 mt-4">
                  <input
                    type="text"
                    placeholder="Type your email"
                    style={{ height: '56px' }}
                    className={`  sw-background-color-ffffff sw-font-size-s
                    sw-text-color-000000 sw-font-family-default sw-border-radius-m sw-width-m sw-padding-top-5xs sw-padding-bottom-5xs
                     sw-border-style-solid sw-border-width-xs sw-border-color-000000 sw-box-shadow-s sw-margin-bottom-6xs sw-margin-left-5xs
                      sw-display-inline-block sw-padding-left-5xs  ${
                        subscribeEmpty ? 'subscribe_input' : ''
                      }`}
                    value={subscribeEmail}
                    onChange={(e) => setSubscribeEmail(e.target.value)}
                  />
                  <a
                    id="sw-email-capture-submit-btn"
                    style={{ height: '56px' }}
                    className="rounded-2 
                  sw-font-size-m sw-font-family-space_mono
                  sw-font-weight-semibold sw-border-radius-default
                  sw-padding-left-m sw-padding-right-m sw-padding-top-5xs 
                  sw-border-style-none sw-border-width-xs sw-border-color-000000 sw-letter-spacing-wider
                  sw-text-decoration-no-underline hover:sw-text-decoration-no-underline  sw-margin-left-5xs subscribe_btn"
                    onClick={handleSubscribe}
                  >
                    Subscribe
                    <img
                      src="/assets/images/Vector.png"
                      alt="logo"
                      className="subscribe_icon img-fluid sw-width-1xs"
                    />{' '}
                    {subscribeLoading && (
                      <div
                        className="spinner-border spinner-border-sm"
                        role="status"
                      >
                        <span className="sr-only">Loading...</span>
                      </div>
                    )}
                  </a>
                </div>
                <div className="mt-10">
                  {subscribeSuccess ? (
                    <h5 className="Space-Grotesk text-success">
                      Thank you for subscribing 🙌
                    </h5>
                  ) : subscribeError ? (
                    <h5 className="Space-Grotesk text-danger">
                      Email address is invalid
                    </h5>
                  ) : subscriberExists ? (
                    <h5 className="Space-Grotesk text-danger">
                      You are already subscribed
                    </h5>
                  ) : null}
                </div>
                <div className="jobs_details row justify-content-center mt-5">
                  <div className="col-md-8 d-flex flex-column flex-lg-row  justify-content-around">
                    <div>
                      <img
                        src="/assets/images/jobs.png"
                        alt="logo"
                        className="img-fluid"
                      />
                      <h4 className="sw-font-family-space_mono fs-32px">
                        {/* {loading ? null : countForJobs.job} */}
                        20+
                      </h4>
                      <small className="Space-Grotesk">JOBS</small>
                    </div>
                    <div>
                      <img
                        src="/assets/images/app.png"
                        alt="logo"
                        className="img-fluid"
                      />
                      <h4 className="sw-font-family-space_mono fs-32px">
                        {/* {loading ? null : countForJobs.applications} */}
                        500+
                      </h4>
                      <small className="Space-Grotesk">APPLICATIONS</small>
                    </div>
                    <div>
                      <img
                        src="/assets/images/comps.png"
                        alt="logo"
                        className="img-fluid"
                      />
                      <h4 className="sw-font-family-space_mono fs-32px">
                        {/* {loading ? null : countForJobs.companies} */}
                        100+
                      </h4>
                      <small className="Space-Grotesk">COMPANIES</small>
                    </div>

                    {/* <div>
                      <img
                        src="/assets/images/salr.png"
                        alt="logo"
                        className="img-fluid"
                      />
                      <h4 className="sw-font-family-space_mono fs-32px">
                        {countForJobs.salaries}
                      </h4>
                      <small className="Space-Grotesk">SALARIES</small>
                    </div> */}
                  </div>
                </div>

                <div className="full-search-2">
                  <div className="d-md-flex align-items-center">
                    <div className="input1">
                      <div className="form-group borders-right">
                        <div className="input-with-icon">
                          <input
                            type="text"
                            className="form-control"
                            disabled={loading}
                            placeholder="Search for web3 job titles, companies or keywords"
                            onChange={(e) => handleSearch(e)}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="input2">
                      <div className="form-group borders-right">
                        <div className="input-with-icon">
                          <ReactMultiSelectCheckboxes
                            placeholderButtonLabel="Location"
                            options={location}
                            value={changeLocationValue}
                            onChange={(e) => setChangeLocationValue(e)}
                          />
                          <img
                            src="/assets/images/loc.png"
                            alt="logo"
                            className="img-fluid "
                          />
                        </div>
                      </div>
                    </div>

                    <div className="input3">
                      <div className="form-group borders-right">
                        <div className="input-with-icon">
                          <ReactMultiSelectCheckboxes
                            placeholderButtonLabel="Contract Type"
                            options={contractType}
                            value={changeContractValue}
                            onChange={(e) => setChangeContractValue(e)}
                          />
                          <img
                            src="/assets/images/remote.png"
                            alt="logo"
                            className="img-fluid "
                          />
                        </div>
                      </div>
                    </div>

                    <div className="input4">
                      <div className="form-group borders-right">
                        <div className="input-with-icon">
                          <ReactMultiSelectCheckboxes
                            placeholderButtonLabel="Language"
                            options={languageName}
                            value={changeLanguageValue}
                            onChange={(e) => setChangeLanguageValue(e)}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="search_btn">
                    <button>
                      <img
                        src="/assets/images/search_input.png"
                        alt="logo"
                        className="img-fluid "
                      />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section id="list1">
          <div className="filter_by_role">
            <Container>
              <div className="row desktop-filter position-relative js-mobile-filter js-filter js-filter-top mt-4">
                <div className="col-md-12  ml-0 p-0 top-filters d-flex align-items-center justify-content-center flex-lg-wrap flex-sm-nowrap overflow_btn ">
                  {tagsFilter &&
                    tagsFilter.map((elem) => {
                      return (
                        <label>
                          <span
                            className={` related-tags filter js-filter-option Space-Grotesk  sw-font-size-s sw-text-color-ffffff  sw-font-weight-semibold sw-background-color-151c48 hover:sw-background-color-ff145f sw-border-style-solid sw-border-width-s sw-border-color-ee034f hover:sw-border-style-solid hover:sw-border-width-s hover:sw-border-color-ffffff sw-border-radius-m sw-padding-top-7xs sw-padding-bottom-7xs sw-padding-left-5xs sw-padding-right-5xs sw-margin-right-6xs sw-cursor-default ${
                              tags && tags.includes(elem) ? 'active' : ''
                            }`}
                          >
                            <input
                              type="checkbox"
                              id={elem}
                              value={elem}
                              onChange={handleChangeTags}
                              checked={tags.includes(elem)}
                            />
                            {elem}
                          </span>
                        </label>
                      )
                    })}
                </div>
              </div>
            </Container>
            <Container>
              <div className="explore_pop">
                <div className="text-white fs-32px fw-700 mb-md-4">
                  Explored Featured &amp; Hand-curated Job Opportunities
                </div>
                <div className="row">
                  <div className="desktop-filter col-sm-12 col-lg-3 ml-0 pl-2 position-relative">
                    <div className="esktop-filter js-filter js-filter-left mt-0">
                      <div
                        className="sw-text-color-ee034f sw-font-family-space_mono fw-600 sw-letter-spacing-wide sw-padding-top-none 
                    sw-padding-bottom-5xs sw-padding-right-6xs sw-padding-left-6xs sw-margin-right-6xs sw-cursor-default fs-19px"
                      >
                        Contract type
                      </div>
                      <div className="d-lg-block d-sm-flex overflow_btn d-flex contract_type_filter">
                        {contractFilter &&
                          contractFilter.map((item) => {
                            return (
                              <label>
                                <div
                                  className={`filter left js-filter-option ml-2 mb-2 sw-font-size-s sw-text-color-ffffff sw-font-family-space_mono sw-font-weight-bold sw-background-color-121c48 hover:sw-background-color-ff145f sw-border-style-solid sw-border-width-s sw-border-color-ff145f hover:sw-border-style-solid hover:sw-border-width-s hover:sw-border-color-ff145f sw-border-radius-m   sw-padding-left-xl sw-padding-right-5xs sw-margin-right-6xs sw-cursor-default pb-1 ${
                                    contract && contract.includes(item)
                                      ? 'active'
                                      : ''
                                  }`}
                                >
                                  <input
                                    type="checkbox"
                                    id={item}
                                    value={item}
                                    onChange={handleChangeContract}
                                    checked={contract.includes(item)}
                                  />
                                  {item}
                                </div>
                              </label>
                            )
                          })}
                      </div>
                    </div>
                    <div className="esktop-filter js-filter js-filter-left">
                      <div
                        className="sw-font-size-xl sw-text-color-ee034f sw-font-family-space_mono sw-font-weight-extrabold sw-letter-spacing-wide sw-padding-top-none 
                      sw-padding-bottom-5xs sw-padding-right-6xs sw-padding-left-6xs sw-margin-right-6xs sw-cursor-default"
                      >
                        Language
                      </div>
                      <div className="d-lg-block d-sm-flex overflow_btn mb-sm-2 contract_type_filter">
                        {favouriteLanguage &&
                          favouriteLanguage.map((elem) => {
                            return (
                              <label>
                                <div
                                  className={`filter left js-filter-option ml-2 mb-2 sw-font-size-s sw-text-color-ffffff sw-font-family-space_mono sw-font-weight-bold sw-background-color-121c48 hover:sw-background-color-ff145f sw-border-style-solid sw-border-width-s sw-border-color-ff145f hover:sw-border-style-solid hover:sw-border-width-s hover:sw-border-color-ff145f sw-border-radius-m sw-padding-top-7xs sw-padding-bottom-7xs sw-padding-left-xl sw-padding-right-5xs sw-margin-right-6xs sw-cursor-default pb-1 ${
                                    language && language.includes(elem._id)
                                      ? 'active'
                                      : ''
                                  }`}
                                >
                                  <input
                                    type="checkbox"
                                    id={elem._id}
                                    checked={
                                      language && language.includes(elem._id)
                                    }
                                    onChange={handleChangeLanguage}
                                  />
                                  {elem.languageName}
                                </div>
                              </label>
                            )
                          })}
                        {unFavouriteLanguage &&
                        unFavouriteLanguage.length > 0 ? (
                          <div
                            className="filter left js-filter-option ml-2 mb-2 sw-font-size-s sw-text-color-ffffff sw-font-family-space_mono sw-font-weight-bold sw-background-color-121c48 hover:sw-background-color-ff145f sw-border-style-solid sw-border-width-s sw-border-color-ff145f hover:sw-border-style-solid hover:sw-border-width-s hover:sw-border-color-ff145f sw-border-radius-m sw-padding-top-7xs sw-padding-bottom-7xs sw-padding-left-xl sw-padding-right-5xs sw-margin-right-6xs sw-cursor-default pb-1"
                            data-value="Other"
                            onClick={handleShowOtherLanguage}
                          >
                            Other
                          </div>
                        ) : null}
                      </div>
                    </div>
                    <div className="esktop-filter js-filter js-filter-left">
                      <div
                        className="sw-font-size-xl sw-text-color-ee034f sw-font-family-space_mono sw-font-weight-extrabold sw-letter-spacing-wide sw-padding-top-none 
                      sw-padding-bottom-5xs sw-padding-right-6xs sw-padding-left-6xs sw-margin-right-6xs sw-cursor-default"
                      >
                        Local Jobs
                      </div>
                      <div className="d-lg-block d-sm-flex overflow_btn mb-sm-2 contract_type_filter">
                        {favouriteLocation &&
                          favouriteLocation.map((item) => {
                            return (
                              <label>
                                <div
                                  className={` filter left js-filter-option ml-2 mb-2 sw-font-size-s sw-text-color-ffffff sw-font-family-space_mono sw-font-weight-bold sw-background-color-121c48 hover:sw-background-color-ff145f sw-border-style-solid sw-border-width-s sw-border-color-ff145f hover:sw-border-style-solid hover:sw-border-width-s hover:sw-border-color-ff145f sw-border-radius-m sw-padding-top-7xs sw-padding-bottom-7xs sw-padding-left-xl sw-padding-right-5xs sw-margin-right-6xs sw-cursor-default pb-1 ${
                                    jobLocation &&
                                    jobLocation.includes(item._id)
                                      ? 'active'
                                      : ''
                                  }`}
                                >
                                  <input
                                    type="checkbox"
                                    id={item._id}
                                    checked={
                                      jobLocation &&
                                      jobLocation.includes(item._id)
                                    }
                                    onChange={handleChangeLocation}
                                  />
                                  {item.locationName}
                                </div>
                              </label>
                            )
                          })}
                        {unFavouriteLocation &&
                        unFavouriteLocation.length > 0 ? (
                          <div
                            className="filter left js-filter-option ml-2 mb-2 sw-font-size-s sw-text-color-ffffff sw-font-family-space_mono sw-font-weight-bold sw-background-color-121c48 hover:sw-background-color-ff145f sw-border-style-solid sw-border-width-s sw-border-color-ff145f hover:sw-border-style-solid hover:sw-border-width-s hover:sw-border-color-ff145f sw-border-radius-m sw-padding-top-7xs sw-padding-bottom-7xs sw-padding-left-xl sw-padding-right-5xs sw-margin-right-6xs sw-cursor-default pb-1"
                            data-value="Other"
                            onClick={handleShowOtherLocation}
                          >
                            Other
                          </div>
                        ) : null}
                      </div>
                    </div>
                  </div>
                  <div className="items  col-sm-12 col-lg-9">
                    <div className="sw-text-color-ee034f sw-font-family-space_mono fw-700 sw-letter-spacing-wide sw-padding-top-none sw-padding-bottom-5xs sw-padding-right-6xs sw-padding-left-6xs sw-margin-right-6xs sw-cursor-default fs-19px">
                      {loading
                        ? null
                        : companyList.length > 0
                        ? 'Explore Jobs'
                        : null}
                    </div>

                    <Backdrop
                      sx={{
                        color: '#fff',
                        zIndex: (theme) => theme.zIndex.drawer + 1,
                      }}
                      open={loading}
                    >
                      <CircularProgress color="inherit" />
                    </Backdrop>
                    <div className="explorjob_blocks_main">
                      {companyList.length > 0 ? (
                        <>
                          {companyList?.map((elem, key) => {
                            return (
                              <div
                                key={key}
                                className="explorjob_blocks_content"
                              >
                                <div className="row align-items-md-center">
                                  <Link href={`/CompaniesPage?id=${elem._id}`}>
                                    <div className="col-md-4 col-12">
                                      <div className="explor_img">
                                        <div className="explor_head">
                                          <img
                                            src={
                                              elem.coverImage
                                                ? elem.coverImage
                                                : coverImage.src
                                            }
                                            className="img-fluid"
                                          />
                                        </div>
                                        <div className="explor_body">
                                          <div className="explor_middle_img">
                                            <img
                                              src={elem.companyLogoImageUrl}
                                              alt="logo"
                                              className="img-fluid "
                                            />
                                          </div>
                                          <h2
                                            className="fs-16px fw-700  sw-font-family-default  
                                                sw-padding-bottom-4xs sw-letter-spacing-normal sw-line-height-normal 
                                                fw-700  fs-16px card_color mb-0"
                                          >
                                            {elem.companyName}
                                          </h2>
                                          <div className="folower_star">
                                            {/* <div className="folower_star_left">
                                            <span>4.9</span>
                                            <span>
                                              <img
                                                src="/assets/images/star1.png"
                                                alt="logo"
                                                className="img-fluid "
                                              />
                                            </span>
                                          </div> */}

                                            <div className="folower_star_right ms-3 ">
                                              <span className="fs-14px">
                                                {elem.follower.length &&
                                                elem.follower.length > 1
                                                  ? 'Followers'
                                                  : 'Follower'}
                                              </span>
                                              <span className="sw-text-color-ff145f fs-14px fw-600 ms-2">
                                                {elem.follower?.length}
                                              </span>
                                            </div>

                                            {/* {elem.follower.length > 0 ? (
                                                <div className="folower_star_right ms-3">
                                                  <span className="fs-14px">
                                                    {elem.follower.length > 1
                                                      ? 'Followers'
                                                      : 'Follower'}
                                                  </span>
                                                  <span className="sw-text-color-ff145f fs-14px fw-600 ms-2">
                                                    {elem.follower &&
                                                      elem.follower.length}
                                                  </span>
                                                </div>
                                              ) : null} */}
                                          </div>
                                          <div className="reviews_salry">
                                            <div className="d-flex justify-content-evenly">
                                              <div>
                                                <p className="fs-13px fw-400 mb-0 nato">
                                                  <span className="fw-600 nato">
                                                    {elem.jobCount}
                                                  </span>
                                                  {elem.jobCount > 1
                                                    ? ' Jobs'
                                                    : ' Job'}
                                                </p>
                                              </div>
                                              {/* <div>
                                              <p className="fs-13px  fw-400  mb-0 nato">
                                                <span className="fw-600 nato">
                                                  0
                                                </span>
                                                Reviews
                                              </p>
                                            </div> */}
                                              {/* <div>
                                              <p className="fs-13px  fw-400  mb-0 nato">
                                                <span className="fw-600 nato">
                                                  0
                                                </span>
                                                Saleries
                                              </p>
                                            </div> */}
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </Link>

                                  {/* <Link
                                      href={`/CompaniesPage?id=${elem._id}&jobId=${elem.jobPosts._id}`}
                                    > */}
                                  <div
                                    className="col-md-8 col-12"
                                    onClick={(e) =>
                                      handleChangepost(
                                        e,
                                        elem._id,
                                        elem.jobPosts._id
                                      )
                                    }
                                  >
                                    <div className="explor_titles">
                                      <h4 className="text-white fs-24px fw-700 nato">
                                        {elem.jobPosts.position}
                                      </h4>

                                      {elem.Hiring_Manager &&
                                        elem.Hiring_Manager.map((item) => {
                                          return (
                                            <>
                                              {item.firstName &&
                                              item.firstName ? (
                                                <p className="text-white opacity-70 fs-18px fw-700">
                                                  By {item.firstName}
                                                </p>
                                              ) : (
                                                <p className="text-white opacity-70 fs-18px fw-700">
                                                  &nbsp;
                                                </p>
                                              )}
                                            </>
                                          )
                                        })}

                                      {elem.relatedTags.length !== 0 ? (
                                        <div className="d-lg-block d-sm-flex overflow_btn mb-sm-2 ">
                                          {elem.relatedTags &&
                                            elem.relatedTags.map((item) => {
                                              return (
                                                <span className="text-white opacity-80">
                                                  {item}
                                                </span>
                                              )
                                            })}
                                        </div>
                                      ) : (
                                        <div className="d-lg-block d-sm-flex overflow_btn mb-sm-2 ">
                                          <p className="text-white opacity-80">
                                            &nbsp;
                                          </p>
                                        </div>
                                      )}
                                      {/* <div className="d-lg-block d-sm-flex overflow_btn mb-sm-2 ">
                                          {elem.relatedTags &&
                                            elem.relatedTags.map((item) => {
                                              return (
                                                <span className="text-white opacity-80">
                                                  {item}
                                                </span>
                                              )
                                            })}
                                        </div> */}

                                      <div className="applicants_date">
                                        <div className="row align-items-sm-end">
                                          <div className="col-md-8">
                                            <div className="d-flex justify-content-between">
                                              <div className="d-flex align-items-center ">
                                                <img
                                                  src="/assets/images/team-line.png"
                                                  alt="logo"
                                                  className="img-fluid "
                                                />
                                                <p className="text-white opacity-70 nato fs-16px my-0">
                                                  {elem.applicantsCount}{' '}
                                                  {elem.applicantsCount > 1
                                                    ? 'Applicants'
                                                    : 'Applicant'}
                                                </p>
                                              </div>
                                              <div className="d-flex align-items-center ">
                                                <img
                                                  src="/assets/images/time.png"
                                                  alt="logo"
                                                  className="img-fluid"
                                                />
                                                <p className="text-white opacity-70 nato fs-16px my-0">
                                                  {toTimestamp(
                                                    elem.jobPosts.createdAt
                                                  )}
                                                </p>
                                              </div>
                                            </div>
                                          </div>
                                          <div className="col-md-4">
                                            <div className="more_info d-flex justify-content-end">
                                              <div>
                                                <button
                                                  className="bg-transparent"
                                                  onClick={handleClickShare}
                                                >
                                                  <img
                                                    src="/assets/images/arrow_round.png"
                                                    alt="logo"
                                                    className="img-fluid"
                                                  />
                                                </button>
                                              </div>
                                              <div>
                                                <button
                                                  className="bg-transparent"
                                                  onClick={handleClickMenu}
                                                >
                                                  <img
                                                    src="/assets/images/more_btn.png"
                                                    alt="logo"
                                                    className="img-fluid"
                                                  />
                                                </button>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  {/* </Link> */}
                                </div>
                              </div>
                            )
                          })}
                          {totalPage && totalPage === skip + 1 ? null : (
                            <div className="seemore_btn " id="seemore_btn">
                              <button
                                className=" fs-20px fw-700 sw-text-color-ff145f text-capitalize "
                                onClick={handleChangeSeemore}
                              >
                                See more
                                <img
                                  src="/assets/images/red_arrow.png"
                                  alt="logo"
                                  className="img-fluid sw-width-1xs ms-3"
                                />
                              </button>
                            </div>
                          )}
                        </>
                      ) : loading ? (
                        <div className="container">
                          <div className="row">
                            <div className="col-md-12 text-center">
                              <h3 className="animate-charcter text-white fs-32px fw-700 sw-text-color-ff145f seemore_btn mb-10 ">
                                Fetching Jobs
                              </h3>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <p className="text-white fs-32px fw-700 sw-text-color-ff145f seemore_btn mb-10  ">
                          Jobs Not Found
                        </p>
                      )}
                    </div>

                    {/* {companyList && companyList.length < limit ? null : (
                      <div className="seemore_btn ">
                        <button
                          className=" fs-20px fw-700 sw-text-color-ff145f "
                          onClick={handleChangeSeemore}
                        >
                          See more
                          <img
                            src="/assets/images/red_arrow.png"
                            alt="logo"
                            className="img-fluid sw-width-1xs"
                          />
                        </button>
                      </div>
                    )} */}

                    {/* <div className='laoder_wave'>
                    <div className="animated-background">
                    </div>
                  </div> */}
                  </div>
                </div>
              </div>
            </Container>

            <section id="partner1">
              <div className="container">
                <div className="row">
                  <div className="col-12 text-center">
                    <h2
                      className="text-white fw-700 sw-text-color-default sw-text-color-ff145f sw-font-family-default  
                 sw-padding-bottom-4xs sw-letter-spacing-normal sw-line-height-normal mb-5 fw-700 text-uppercase fs-16px "
                    >
                      Trusted by
                    </h2>
                  </div>
                </div>
                <div className="row justify-content-center">
                  <div className="col-md-10">
                    <TrustedByslide />
                  </div>
                </div>
              </div>
            </section>
            <Container>
              <div className="hiring_talent">
                <div className="text-white fs-32px fw-700 mb-4">
                  Hiring a Talent
                </div>
                <div className="tealent-main">
                  <div className="d-flex flex-column flex-lg-row align-items-center">
                    <div className="star_img">
                      <img
                        src="/assets/images/usersetting.png"
                        alt="logo"
                        className="img-fluid "
                      />
                    </div>
                    <div className="ms-3">
                      <p className="Space-Grotesk fs-16px fw-700 text-white m-0">
                        <span className="opacity-70">
                          Building a performing diverse team in the intersection
                          of
                        </span>{' '}
                        Programming, Design & Economics{' '}
                        <span className="opacity-70">
                          is hard. We find and connect you to qualified
                          blockchain & crypto talent to build your rock-star
                          team!
                        </span>{' '}
                        🎸
                      </p>
                    </div>
                    <div className="hiring btn">
                      <button
                        className="fs-16px text-white fw-700"
                        onClick={() => handleManageCreateCompany()}
                      >
                        <img
                          src="/assets/images/hiring.png"
                          alt="logo"
                          className="img-fluid me-2"
                        />
                        Start Hiring
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              {/* <div className="row explore_salaries section_space">
                <div className="col-lg-12">
                  <div className="text-white fs-32px fw-700 mb-4">
                    Explore Salaries in Web3 Companies
                  </div>
                  <div className="box_bg_border no_bg p-4 text-white">
                    <h3 className="Space-Grotesk fs-20px fw-600">
                      Salaries by Company{' '}
                    </h3>
                    <p className="nato fs-14px color-white fw-400 mb-4">
                      Click on a company to explore salaries, benefits, and
                      more.
                    </p>

                    <div className="input-group mb-3 search-title">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Search your favorite companies"
                        aria-label="Recipient's username"
                        aria-describedby="basic-addon2"
                      />
                      <div className="input-group-append">
                        <div className="search_btn">
                          <button>
                            <img
                              src="/assets/images/search_input.png"
                              alt="logo"
                              className="img-fluid "
                            />
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="pop-compny">
                      <p className="nato fs-14px color-white fw-400">
                        Popular Companies
                      </p>

                      <div className="row">
                        <div className="col-lg-3 mb-4">
                          <div className="pop-compny-block">
                            <img
                              src="/assets/images/googles.png"
                              alt="logo"
                              className="img-fluid"
                            />
                            <span className="ms-3">Google</span>
                          </div>
                        </div>
                        <div className="col-lg-3 mb-4">
                          <div className="pop-compny-block">
                            <img
                              src="/assets/images/amazone.png"
                              alt="logo"
                              className="img-fluid"
                            />
                            <span className="ms-3">Amazone</span>
                          </div>
                        </div>
                        <div className="col-lg-3 mb-4">
                          <div className="pop-compny-block">
                            <img
                              src="/assets/images/apple.png"
                              alt="logo"
                              className="img-fluid"
                            />
                            <span className="ms-3">Apple</span>
                          </div>
                        </div>
                        <div className="col-lg-3 mb-4">
                          <div className="pop-compny-block">
                            <img
                              src="/assets/images/facebook-v1.png"
                              alt="logo"
                              className="img-fluid"
                            />
                            <span className="ms-3">Facebook</span>
                          </div>
                        </div>
                        <div className="col-lg-3 mb-4">
                          <div className="pop-compny-block">
                            <img
                              src="/assets/images/coinbase.png"
                              alt="logo"
                              className="img-fluid"
                            />
                            <span className="ms-3">Coinbase</span>
                          </div>
                        </div>
                        <div className="col-lg-3 mb-4">
                          <div className="pop-compny-block">
                            <img
                              src="/assets/images/databrickes.png"
                              alt="logo"
                              className="img-fluid"
                            />
                            <span className="ms-3">Databrickes</span>
                          </div>
                        </div>
                        <div className="col-lg-3 mb-4">
                          <div className="pop-compny-block">
                            <img
                              src="/assets/images/uber.png"
                              alt="logo"
                              className="img-fluid"
                            />
                            <span className="ms-3">Uber</span>
                          </div>
                        </div>
                        <div className="col-lg-3 mb-4">
                          <div className="pop-compny-block">
                            <img
                              src="/assets/images/rebaked.png"
                              alt="logo"
                              className="img-fluid"
                            />
                            <span className="ms-3">Rebaked</span>
                          </div>
                        </div>
                        <div className="col-lg-3 mb-4">
                          <div className="pop-compny-block">
                            <img
                              src="/assets/images/microsoft.png"
                              alt="logo"
                              className="img-fluid"
                            />
                            <span className="ms-3">Microsoft</span>
                          </div>
                        </div>
                        <div className="col-lg-3 mb-4">
                          <div className="pop-compny-block">
                            <img
                              src="/assets/images/googles.png"
                              alt="logo"
                              className="img-fluid"
                            />
                            <span className="ms-3">Google</span>
                          </div>
                        </div>
                        <div className="col-lg-3 mb-4">
                          <div className="pop-compny-block">
                            <img
                              src="/assets/images/amazone.png"
                              alt="logo"
                              className="img-fluid"
                            />
                            <span className="ms-3">Amazone</span>
                          </div>
                        </div>
                        <div className="col-lg-3 mb-4">
                          <div className="pop-compny-block">
                            <img
                              src="/assets/images/apple.png"
                              alt="logo"
                              className="img-fluid"
                            />
                            <span className="ms-3">Apple</span>
                          </div>
                        </div>
                        <div className="col-lg-3 mb-4">
                          <div className="pop-compny-block">
                            <img
                              src="/assets/images/googles.png"
                              alt="logo"
                              className="img-fluid"
                            />
                            <span className="ms-3">Google</span>
                          </div>
                        </div>
                        <div className="col-lg-3 mb-4">
                          <div className="pop-compny-block">
                            <img
                              src="/assets/images/amazone.png"
                              alt="logo"
                              className="img-fluid"
                            />
                            <span className="ms-3">Amazone</span>
                          </div>
                        </div>
                        <div className="col-lg-3 mb-4">
                          <div className="pop-compny-block">
                            <img
                              src="/assets/images/apple.png"
                              alt="logo"
                              className="img-fluid"
                            />
                            <span className="ms-3">Apple</span>
                          </div>
                        </div>
                        <div className="col-lg-3 mb-4">
                          <div className="pop-compny-block">
                            <img
                              src="/assets/images/facebook-v1.png"
                              alt="logo"
                              className="img-fluid"
                            />
                            <span className="ms-3">Facebook</span>
                          </div>
                        </div>
                        <div className="col-lg-3 mb-4">
                          <div className="pop-compny-block">
                            <img
                              src="/assets/images/coinbase.png"
                              alt="logo"
                              className="img-fluid"
                            />
                            <span className="ms-3">Coinbase</span>
                          </div>
                        </div>
                        <div className="col-lg-3 mb-4">
                          <div className="pop-compny-block">
                            <img
                              src="/assets/images/databrickes.png"
                              alt="logo"
                              className="img-fluid"
                            />
                            <span className="ms-3">Databrickes</span>
                          </div>
                        </div>
                        <div className="col-lg-3 mb-4">
                          <div className="pop-compny-block">
                            <img
                              src="/assets/images/uber.png"
                              alt="logo"
                              className="img-fluid"
                            />
                            <span className="ms-3">Uber</span>
                          </div>
                        </div>
                        <div className="col-lg-3 mb-4">
                          <div className="pop-compny-block">
                            <img
                              src="/assets/images/rebaked.png"
                              alt="logo"
                              className="img-fluid"
                            />
                            <span className="ms-3">Rebaked</span>
                          </div>
                        </div>
                        <div className="col-lg-3 mb-4">
                          <div className="pop-compny-block">
                            <img
                              src="/assets/images/microsoft.png"
                              alt="logo"
                              className="img-fluid"
                            />
                            <span className="ms-3">Microsoft</span>
                          </div>
                        </div>
                        <div className="col-lg-3 mb-4">
                          <div className="pop-compny-block">
                            <img
                              src="/assets/images/googles.png"
                              alt="logo"
                              className="img-fluid"
                            />
                            <span className="ms-3">Google</span>
                          </div>
                        </div>
                        <div className="col-lg-3 mb-4">
                          <div className="pop-compny-block">
                            <img
                              src="/assets/images/amazone.png"
                              alt="logo"
                              className="img-fluid"
                            />
                            <span className="ms-3">Amazone</span>
                          </div>
                        </div>
                        <div className="col-lg-3 mb-4">
                          <div className="pop-compny-block">
                            <img
                              src="/assets/images/apple.png"
                              alt="logo"
                              className="img-fluid"
                            />
                            <span className="ms-3">Apple</span>
                          </div>
                        </div>
                      </div>
                      <div className="text-center pt-3">
                        <p className="nato  fs-16px fw-600 opacity-80 mb-0 text-white">
                          <button className="bg-transparent border-0 text-white">
                            See More{' '}
                            <img
                              src="/assets/images/arrow-down-s-line.png"
                              alt="logo"
                              className="img-fluid "
                            />
                          </button>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div> */}
              {/* <div className="row add_your_salary">
                <div className="col-lg-12">
                  <div className="row box_bg_border">
                    <div className="add_your_salary_content text-white">
                      <div className="align-items-md-center row">
                        <div className="col-md-6">
                          <div className="add_your_salary_media">
                            <img
                              src="/assets/images/add-salary.png"
                              alt=""
                              className="img-fluid"
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="leftside">
                            <h3 className="Space-Grotesk fs-40px fw-600">
                              Add Your Salary
                            </h3>
                            <p className="nato fs-20px color-white fw-400 mb-4">
                              Over 100,000 salaries submitted!
                            </p>
                            <div className="salry-manually">
                              <div className="p-4 fs-18px Space-Grotesk">
                                <img
                                  src="/assets/images/MASK.png"
                                  alt="logo"
                                  className="img-fluid me-4"
                                />
                                Anonymous user information
                              </div>
                              <div className="p-4 fs-18px Space-Grotesk">
                                <img
                                  src="/assets/images/time-line.png"
                                  alt="logo"
                                  className="img-fluid me-4"
                                />
                                Takes about 50 seconds
                              </div>
                            </div>
                            <div className="enter-manual mt-4">
                              <button
                                className="btn_pink big"
                                onClick={handleShow}
                              >
                                <img
                                  src="/assets/images/manuals.png
                                                            "
                                  alt="logo"
                                  className="img-fluid me-2"
                                />
                                Enter Manually
                              </button>

                              <Modal
                                show={show}
                                onHide={handleClose}
                                className="enter-manual-model"
                                size="lg"
                              >
                                <Modal.Body>
                                  <div>
                                    <h4 className="Space-Grotesk fs-20px fw-600 text-white">
                                      Add Your Salary
                                    </h4>
                                    <p>
                                      {' '}
                                      <img
                                        src="/assets/images/manully.png"
                                        alt="logo"
                                        className="img-fluid "
                                      />
                                      <span className="nato fs-14px fw-600 text-white ms-2 border-bottom">
                                        Upload an Offer Letter, Yearly Comp
                                        Statement, W2, Etc
                                      </span>{' '}
                                      <span className="nato fs-14px fw-40 color-white1">
                                        to verify your submission
                                      </span>
                                    </p>
                                    <h4 className="Space-Grotesk fs-20px fw-600 text-white">
                                      Company & Title Information
                                    </h4>

                                    <Form>
                                      <Form.Group
                                        className="my-3"
                                        controlId="formBasicEmail" 
                                      >
                                        <Form.Label className="Space-Grotesk fs-14px fw-600 color-white">
                                          Company Name
                                        </Form.Label>
                                        <Form.Control
                                          type="text"
                                          placeholder="Type your company name"
                                        />
                                      </Form.Group>

                                      <Form.Group
                                        className="my-3"
                                        controlId="formBasicPassword"
                                      >
                                        <Form.Label className="Space-Grotesk fs-14px fw-600 color-white">
                                          Job Title
                                        </Form.Label>
                                        <Form.Control
                                          type="password"
                                          placeholder="Type your company name"
                                        />
                                      </Form.Group>
                                      <Form.Label className="Space-Grotesk fs-14px fw-600 color-white">
                                        Job Family
                                      </Form.Label>
                                      <Form.Select aria-label="Default select example my-3">
                                        <option>Select Your Job Family</option>
                                        <option value="1">One</option>
                                        <option value="2">Two</option>
                                        <option value="3">Three</option>
                                      </Form.Select>
                                      <div className="row my-3">
                                        <div className="col-lg-6">
                                          <Form.Group controlId="formBasicEmail"> 
                                            <Form.Label className="Space-Grotesk fs-14px fw-600 color-white">
                                              Employment Status
                                            </Form.Label>
                                            <Form.Select aria-label="Default select example my-3">
                                              <option>
                                                Select Employment Status
                                              </option>
                                              <option value="1">
                                                Status 1
                                              </option>
                                              <option value="2">
                                                Status 2
                                              </option>
                                            </Form.Select>
                                          </Form.Group>
                                        </div>
                                        <div className="col-lg-6">
                                          <Form.Group controlId="formBasicEmail"> 
                                            <Form.Label className="Space-Grotesk fs-14px fw-500 color-white">
                                              Seniority Level
                                            </Form.Label>
                                            <Form.Select aria-label="Default select example my-3">
                                              <option>
                                                Select Seniority Level
                                              </option>
                                              <option value="1">Level 1</option>
                                              <option value="2">Level 2</option>
                                            </Form.Select>
                                          </Form.Group>
                                        </div>
                                      </div>
                                      <div className="row my-3">
                                        <div className="col-lg-6">
                                          <Form.Group>
                                            <Form.Label className="Space-Grotesk fs-14px fw-600 color-white">
                                              Token Allocations / Stock options{' '}
                                            </Form.Label>
                                            <Form.Control
                                              type="text"
                                              placeholder="Enter expected $USD value of tokens/ stocks"
                                            />
                                          </Form.Group>
                                        </div>
                                        <div className="col-lg-6">
                                          <div
                                            className="add-salary-alert fade show"
                                            role="alert"
                                          >
                                            <img
                                              src="/assets/images/alert-blue.png"
                                              alt="logo"
                                              className="img-fluid alert-blue"
                                            />
                                            <p className="Space-Grotesk fs-12px fw-400 text-white opacity-50 mb-0">
                                              Please input your expected annual
                                              Stock Option/ Token Allocation
                                              converted to expected $USD
                                              valuation
                                            </p>
                                            <button
                                              type="button"
                                              className="bg-transparent border-0 blue-crossbtn"
                                              data-bs-dismiss="alert"
                                              aria-label="Close"
                                            >
                                              {' '}
                                              <img src="/assets/images/blue-cross.png" />
                                            </button>
                                          </div>
                                        </div>
                                      </div>
                                      <h4 className="Space-Grotesk fs-20px fw-600 text-white">
                                        Salary Details*
                                      </h4>
                                      <div className="row my-3">
                                        <div className="col-lg-6">
                                          <Form.Group>
                                            <Form.Label className="Space-Grotesk fs-14px fw-600 color-white">
                                              Annual Gross Salary (Excluding
                                              Bonus){' '}
                                            </Form.Label>
                                            <Form.Control
                                              type="text"
                                              placeholder="Enter Amount base pay per Year"
                                            />
                                          </Form.Group>
                                        </div>
                                        <div className="col-lg-6">
                                          <div
                                            className="add-salary-alert fade show"
                                            role="alert"
                                          >
                                            <img
                                              src="/assets/images/alert-blue.png"
                                              alt="logo"
                                              className="img-fluid alert-blue"
                                            />
                                            <p className="Space-Grotesk fs-12px fw-400 text-white opacity-50 mb-0">
                                              Please input your annual gross
                                              salary converted to USD at current
                                              exchange rates.
                                            </p>
                                            <button
                                              type="button"
                                              className="bg-transparent border-0 blue-crossbtn"
                                              data-bs-dismiss="alert"
                                              aria-label="Close"
                                            >
                                              {' '}
                                              <img src="/assets/images/blue-cross.png" />
                                            </button>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="work-exp-loc">
                                        <h4 className="Space-Grotesk fs-20px fw-600 text-white mb-4">
                                          Other Benefits:
                                        </h4>
                                        <ul className="d-lg-flex ps-0 check_bx_group">
                                          <li>
                                            <label className="d-flex">
                                              <input
                                                type="checkbox"
                                                name="checkbox"
                                                className="text-white rounded"
                                              />
                                              <span> Internet Connection</span>
                                            </label>
                                          </li>
                                          <li>
                                            <label className="d-flex">
                                              <input
                                                type="checkbox"
                                                name="checkbox"
                                                className="text-white rounded"
                                              />
                                              <span>Paid Conferences </span>
                                            </label>
                                          </li>
                                          <li>
                                            <label className="d-flex">
                                              <input
                                                type="checkbox"
                                                name="checkbox"
                                                className="text-white rounded"
                                              />
                                              <span>Trainings </span>
                                            </label>
                                          </li>
                                          <li>
                                            <label className="d-flex">
                                              <input
                                                type="checkbox"
                                                name="checkbox"
                                                className="text-white rounded"
                                              />
                                              <span>Gym Subscription </span>
                                            </label>
                                          </li>
                                          <li>
                                            <label className="d-flex">
                                              <input
                                                type="checkbox"
                                                name="checkbox"
                                                className="text-white rounded"
                                              />
                                              <span>Home Office Equipment</span>
                                            </label>
                                          </li>
                                          <li>
                                            <label className="d-flex">
                                              <input
                                                type="checkbox"
                                                name="checkbox"
                                                className="text-white rounded"
                                              />
                                              <span>Company Gatherings </span>
                                            </label>
                                          </li>
                                          <li>
                                            <label className="d-flex">
                                              <input
                                                type="checkbox"
                                                name="checkbox"
                                                className="text-white rounded"
                                              />
                                              <span>
                                                Cell-Phone connection{' '}
                                              </span>
                                            </label>
                                          </li>
                                          <li>
                                            <label className="d-flex">
                                              <input
                                                type="checkbox"
                                                name="checkbox"
                                                className="text-white rounded"
                                              />
                                              <span>Private Insurance</span>
                                            </label>
                                          </li>
                                        </ul>
                                      </div>
                                      <div className="enter-manual">
                                        <button className="submit-salry-btn">
                                          <img
                                            src="/assets/images/sub-salry.png"
                                            alt="logo"
                                            className="img-fluid me-2"
                                          />
                                          Submit Salary
                                        </button>
                                      </div>
                                    </Form>
                                  </div>
                                </Modal.Body>
                              </Modal>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div> */}
              {/* <div className="row jobs_by_role">
                <div className="col-lg-12">
                  <div className="box_bg_border no_bg text-white">
                    <h3 className="Space-Grotesk fs-20px fw-600">
                      Jobs by Role
                    </h3>
                    <p className="nato fs-14px color-white fw-400 mb-4">
                      Click on a title or role to explore salaries.
                    </p>
                    <div className="row">
                      <div className="col-md-4">
                        <div className="block">
                          <div className="block_icon">
                            <img
                              src="/assets/images/engineering-export.png"
                              alt=""
                            />
                          </div>
                          <div className="block_content">
                            <div className="block_content_title">
                              Engineering Expert
                            </div>
                            <p>(221 open possitions)</p>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="block">
                          <div className="block_icon">
                            <img src="/assets/images/empl.png" alt="" />
                          </div>
                          <div className="block_content">
                            <div className="block_content_title">
                              Business Development and Sales Experts
                            </div>
                            <p>(221 open possitions)</p>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="block">
                          <div className="block_icon">
                            <img
                              src="/assets/images/marketing-jobs.png"
                              alt=""
                            />
                          </div>
                          <div className="block_content">
                            <div className="block_content_title">Marketing</div>
                            <p>(221 open possitions)</p>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="block">
                          <div className="block_icon">
                            <img
                              src="/assets/images/humen-resoureses.png"
                              alt=""
                            />
                          </div>
                          <div className="block_content">
                            <div className="block_content_title">
                              Human Resources
                            </div>
                            <p>(221 open possitions)</p>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="block">
                          <div className="block_icon">
                            <img
                              src="/assets/images/accounting-finance.png"
                              alt=""
                            />
                          </div>
                          <div className="block_content">
                            <div className="block_content_title">
                              Accounting and Finance
                            </div>
                            <p>(221 open possitions)</p>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="block">
                          <div className="block_icon">
                            <img src="/assets/images/operations.png" alt="" />
                          </div>
                          <div className="block_content">
                            <div className="block_content_title">Operaions</div>
                            <p>(221 open possitions)</p>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="block">
                          <div className="block_icon">
                            <img src="/assets/images/legal.png" alt="" />
                          </div>
                          <div className="block_content">
                            <div className="block_content_title">
                              Legal and Compliance
                            </div>
                            <p>(221 open possitions)</p>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="block">
                          <div className="block_icon">
                            <img src="/assets/images/product.png" alt="" />
                          </div>
                          <div className="block_content">
                            <div className="block_content_title">Product</div>
                            <p>(221 open possitions)</p>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="block">
                          <div className="block_icon">
                            <img
                              src="/assets/images/art-and-design.png"
                              alt=""
                            />
                          </div>
                          <div className="block_content">
                            <div className="block_content_title">
                              Art and Design
                            </div>
                            <p>(221 open possitions)</p>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="block">
                          <div className="block_icon">
                            <img
                              src="/assets/images/customer-support.png"
                              alt=""
                            />
                          </div>
                          <div className="block_content">
                            <div className="block_content_title">
                              Customer Support
                            </div>
                            <p>(221 open possitions)</p>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="block">
                          <div className="block_icon">
                            <img src="/assets/images/data.png" alt="" />
                          </div>
                          <div className="block_content">
                            <div className="block_content_title">Data</div>
                            <p>(221 open possitions)</p>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="block">
                          <div className="block_icon">
                            <img src="/assets/images/community.png" alt="" />
                          </div>
                          <div className="block_content">
                            <div className="block_content_title">Community</div>
                            <p>(221 open possitions)</p>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="block">
                          <div className="block_icon">
                            <img src="/assets/images/strategy.png" alt="" />
                          </div>
                          <div className="block_content">
                            <div className="block_content_title">Strategy</div>
                            <p>(221 open possitions)</p>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="block">
                          <div className="block_icon">
                            <img src="/assets/images/research.png" alt="" />
                          </div>
                          <div className="block_content">
                            <div className="block_content_title">Research</div>
                            <p>(221 open possitions)</p>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="block">
                          <div className="block_icon">
                            <img src="/assets/images/content.png" alt="" />
                          </div>
                          <div className="block_content">
                            <div className="block_content_title">Content</div>
                            <p>(221 open possitions)</p>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="text-center pt-4">
                      <p className="nato  fs-16px fw-600 opacity-80 mb-0 text-white">
                        <button className="bg-transparent border-0 text-white">
                          See More Jobs{' '}
                          <img
                            src="/assets/images/arrow-down-s-line.png"
                            alt="logo"
                            className="img-fluid"
                          />
                        </button>
                      </p>
                    </div>
                  </div>
                </div>
              </div> */}
            </Container>
            {/* <section className="recomandation section_space">
              <Container>
                <div className="text-white fs-32px fw-700 mb-4">
                  Improve Your Recommendations
                </div>
                <div className="d-flex align-items-baseline">
                  <div className="star_img">
                    <img
                      src="/assets/images/crown.png"
                      alt="logo"
                      className="img-fluid "
                    />
                  </div>
                  <div className="ms-3">
                    <p className="Space-Grotesk fs-20px fw-700 text-white m-0">
                      Best organisations
                      <span className="fw-400"> to work as identified by </span>
                      our Community
                    </p>
                    <p className="text-white fs-18px Space-Grotesk mt-2">
                      <span className="fw-400 opacity-70">Find the best</span>
                      <span className="sw-text-color-ff145f fw-700">
                        {' '}
                        Web 3.0 companies{' '}
                      </span>
                      <span className="fw-400 opacity-70">
                        hiring to build your
                      </span>
                      <span className="sw-text-color-ff145f fw-700">
                        {' '}
                        crypto career!
                      </span>
                    </p>
                  </div>
                </div>
              </Container>
            </section> */}
            {/* <section className="wallet_slides mt-4">
              <Walletslide />
            </section> */}
            {/* <section className="latest_review">
              <Container>
                <div className="d-flex align-items-baseline">
                  <div className="star_img">
                    <img
                      src="/assets/images/review.png"
                      alt="logo"
                      className="img-fluid "
                    />
                  </div>
                  <div className="ms-3">
                    <p className="text-white fs-18px Space-Grotesk fw-700 mt-2">
                      Latest Review
                      <span className="opacity-70">
                        {' '}
                        for recommended company
                      </span>
                      <span className="sw-text-color-ff145f fw-700 opacity-1">
                        {' '}
                        Check it
                      </span>
                    </p>
                  </div>
                </div>
              </Container>
              <div className="latest_review_slides">
                <LatestReviewSlide />
              </div>
            </section> */}
          </div>
        </section>
        {/* <section className="explore_sec section_space">
          <Container>
            <div className="text-white fs-32px fw-700 mb-4">
              Explore Sectors
            </div>
            <div className="row">
              <div className=" col  mb-4">
                <div className="d-flex align-items-center">
                  <div>
                    <img
                      src="/assets/images/retail.png"
                      alt="logo"
                      className="img-fluid "
                    />
                  </div>
                  <div className="ms-3">
                    <span className="text-white fw-600 fs-16px nato ms-0">
                      Retail
                    </span>
                  </div>
                </div>
              </div>
              <div className=" col mb-4">
                <div className="d-flex align-items-center">
                  <div>
                    <img
                      src="/assets/images/finance.png"
                      alt="logo"
                      className="img-fluid "
                    />
                  </div>
                  <div className="ms-3">
                    <span className="text-white fw-600 fs-16px nato ms-0">
                      Finance
                    </span>
                  </div>
                </div>
              </div>
              <div className="col mb-4">
                <div className="d-flex align-items-center">
                  <div>
                    <img
                      src="/assets/images/defi.png"
                      alt="logo"
                      className="img-fluid "
                    />
                  </div>
                  <div className="ms-3">
                    <span className="text-white fw-600 fs-16px nato ms-0">
                      DeFi
                    </span>
                  </div>
                </div>
              </div>
              <div className="col mb-4">
                <div className="d-flex align-items-center">
                  <div>
                    <img
                      src="/assets/images/trading.png"
                      alt="logo"
                      className="img-fluid "
                    />
                  </div>
                  <div className="ms-3">
                    <span className="text-white fw-600 fs-16px nato ms-0">
                      Trading
                    </span>
                  </div>
                </div>
              </div>
              <div className="col mb-4">
                <div className="d-flex align-items-center">
                  <div>
                    <img
                      src="/assets/images/marketing.png"
                      alt="logo"
                      className="img-fluid "
                    />
                  </div>
                  <div className="ms-3">
                    <span className="text-white fw-600 fs-16px nato ms-0">
                      Marketing
                    </span>
                  </div>
                </div>
              </div>
              <div className="col mb-4">
                <div className="d-flex align-items-center">
                  <div>
                    <img
                      src="/assets/images/custservice.png"
                      alt="logo"
                      className="img-fluid "
                    />
                  </div>
                  <div className="ms-3">
                    <span className="text-white fw-600 fs-16px nato ms-0 white-space-nowrap">
                      Customer Services
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </Container>
        </section> */}

        <section className="testimonial_main section_space">
          <Container>
            <div className="text-white fs-32px fw-700  text-center testimonial_mainhead">
              Testimonials
            </div>
            <div className="testimonial_content">
              <div className="d-md-flex ">
                <div className="test_img">
                  <img
                    src="/assets/images/Nikolaos.jpg"
                    alt="logo"
                    className="img-fluid "
                  />
                </div>
                <div className="ms-md-4">
                  <p className="nato  fs-18px text-white mb-1">
                    I'd recommend using 0xCareer
                    <span className="opacity-70 fw-400 ps-2">
                      0xcareer has been instrumental in helping us find the most
                      relevant and talented candidates in the Web3, Blockchain,
                      Crypto, and NFT industries. As the founder of reBaked, I
                      was impressed by the depth of knowledge and expertise
                      possessed by the individuals recommended to us by
                      0xcareer. Thanks to 0xcareer, we were able to build a
                      highly skilled team that has greatly contributed to the
                      success of our business.
                    </span>
                  </p>
                  <p className="fs-18px fw-800 text-white mb-2">
                    Nikolaos Kostopoulos
                  </p>
                  <p className="fs-18px fw-600 text-white mb-1">
                    <span className="opacity-70 fw-400">Founder of </span>
                    <a
                      href="https://www.rebaked.com/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      reBaked
                    </a>
                  </p>
                </div>
              </div>
            </div>
            <div className="testimonial_content mt-md-4">
              <div className="d-md-flex ">
                <div className="test_img">
                  <img
                    src="/assets/images/Angela.jpg"
                    alt="logo"
                    className="img-fluid "
                  />
                </div>
                <div className="ms-md-4">
                  <p className="nato  fs-18px text-white mb-1">
                    I'd recommend using 0xCareer
                    <span className="opacity-70 fw-400 ps-2">
                      Finding the right talent in the fast-paced world of Web3,
                      Blockchain, Crypto, and NFTs can be a daunting task.
                      However, with 0xcareer, our journey to recruit skilled
                      professionals became seamless.
                    </span>
                  </p>
                  <p className="fs-18px fw-700 text-white mb-2">Angela Douka</p>
                  <p className="fs-18px fw-700 text-white mb-1">
                    <span className="opacity-70 fw-400">
                      Marketing Coordinator at{' '}
                    </span>
                    <a
                      href="https://crowdhack.io/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      CrowdHack
                    </a>
                  </p>
                </div>
              </div>
            </div>
            <div className="testimonial_content mt-md-4">
              <div className="d-md-flex ">
                <div className="test_img">
                  <img
                    src="/assets/images/deepshah.jpg"
                    alt="logo"
                    className="img-fluid "
                  />
                </div>
                <div className="ms-md-4">
                  <p className="nato  fs-18px text-white mb-1">
                    I'd recommend using 0xCareer
                    <span className="opacity-70 fw-400 ps-2">
                      As a founder of CodeZeros, it is crucial for me to have a
                      team of experts who understand the complexities of Web3,
                      Blockchain, Crypto, and NFTs. 0xcareer played a vital role
                      in helping us achieve that goal. Their platform presented
                      us with a diverse pool of highly relevant candidates, each
                      possessing a deep understanding of the industry.
                    </span>
                  </p>
                  <p className="fs-18px fw-800 text-white mb-2">Deep Shah</p>
                  <p className="fs-18px fw-700 text-white mb-1">
                    <span className="opacity-70 fw-400">Founder of </span>
                    <a
                      href="https://www.codezeros.com/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      CodeZeros
                    </a>
                  </p>
                </div>
              </div>
            </div>
            <div className="testimonial_content mt-md-4">
              <div className="d-md-flex ">
                <div className="test_img">
                  <img
                    src="/assets/images/harshlink.jpg"
                    alt="logo"
                    className="img-fluid "
                  />
                </div>
                <div className="ms-md-4">
                  <p className="nato  fs-18px text-white mb-1">
                    I'd recommend using 0xCareer
                    <span className="opacity-70 fw-400 ps-2">
                      I would highly recommend 0xcareer to anyone seeking the
                      best talent in the field.
                    </span>
                  </p>
                  <p className="fs-18px fw-800 text-white mb-2">Harsh Link</p>
                  <p className="fs-18px fw-700 text-white mb-1">
                    <span className="opacity-70 fw-400">
                      Founder of Harsh Link Connect
                    </span>
                  </p>
                </div>
              </div>
            </div>
          </Container>
        </section>
        <SignupFooter />
      </div>
    </>
  )
}

export default HomePage
