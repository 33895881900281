import React, { FC } from 'react'
import Head from 'next/head'
import { useRouter } from 'next/router'

interface HeaderProps {
  title: string
  description: string
  type: string
  image: string
}

const HeadMetaData: FC<HeaderProps> = ({ title, description, type, image }) => {
  const router = useRouter()
  return (
    <Head>
      <meta charSet="UTF-8" />
      <meta
        name="viewport"
        content="width=device-width, initial-scale=1, minimum-scale=1"
      />
      <link href="http://gmpg.org/xfn/11" />
      <title>{title || 'Webclues Infotech'}</title>
      <meta name="dc.title" content={title || ''} />
      <meta name="dc.description" content={description || ''} />
      <meta name="dc.relation" content={router.asPath || ''} />
      <meta name="dc.source" content="https://www.webcluesinfotech.com/" />
      <meta name="dc.language" content="en_US" />
      <meta name="description" content={description || ''} />
      <meta name="robots" content="index, follow" />
      <meta
        name="googlebot"
        content="index, follow, max-snippet:-1,max-image-preview:large, max-video-preview:-1"
      />
      <meta
        name="bingbot"
        content="index, follow, max-snippet:-1,max-image-preview:large, max-video-preview:-1"
      />
      <link rel="canonical" href={router.asPath || ''} />
      <meta property="og:url" content={router.asPath || ''} />
      <meta property="og:site_name" content="WebcluesInfotech" />
      <meta property="og:locale" content="en_US" />
      <meta property="og:type" content={type || ''} />
      <meta property="og:title" content={title || ''} />
      <meta property="og:description" content={description || ''} />
      <meta property="og:image" content={image || ''} />
      <meta property="og:image:secure_url" content={image || ''} />
      <meta property="og:image:width" content="1200" />
      <meta property="og:image:height" content="675" />
      <meta name="twitter:card" content="summary" />
      <meta name="twitter:title" content={title || ''} />
      <meta name="twitter:description" content={description || ''} />
      <meta name="twitter:image" content={image || ''} />
    </Head>
  )
}

export default HeadMetaData
