import { FC } from 'react'
import { PageDetailsInterface } from 'constants/pageMetaTags'
import HeadMetaData from 'components/HeadMetaData'

const PageWrapper: FC<PageDetailsInterface> = ({ meta, children }) => {
  return (
    <div>
      <HeadMetaData {...meta} />
      {children}
    </div>
  )
}

export default PageWrapper
