import React, { useEffect, useState } from 'react'
import Slider from 'react-slick'

export default function TrustedByslide() {
  let settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 3,
    arrows: false,
    autoplay: false,
    responsive: [
      {
        breakpoint: 1360,
        settings: {
          slidesToShow: 6,
          // slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  }

  return (
    <Slider {...settings} className="trusted_slider">
      <div className="slider_item">
        <div>
          <img
            src="/assets/images/slide3.png"
            alt="slides"
            className=" img-fluid"
          />
        </div>
      </div>
      <div className="slider_item">
        <div>
          <img
            src="/assets/images/slide4.png"
            alt="slides"
            className=" img-fluid"
          />
        </div>
      </div>
      <div className="slider_item">
        <div>
          <img
            src="/assets/images/slide5.png"
            alt="slides"
            className=" img-fluid"
          />
        </div>
      </div>
      <div className="slider_item">
        <div>
          <img
            src="/assets/images/slide6.png"
            alt="slides"
            className=" img-fluid"
          />
        </div>
      </div>
      <div className="slider_item">
        <div>
          <img
            src="/assets/images/slides1.png"
            alt="slides"
            className=" img-fluid"
          />
        </div>
      </div>
    </Slider>
  )
}
