import { FC } from 'react'
import HomePage from 'views/HomePage'
import PageWrapper from 'components/PageWrapper'
import PAGE_CONSTANT_TEXTS from 'constants/pageMetaTags'

const Home: FC = () => {
  return (
    <PageWrapper {...PAGE_CONSTANT_TEXTS.home}>
      <HomePage />
    </PageWrapper>
  )
}

export default Home
