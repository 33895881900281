import firebase from 'firebase/app'
import 'firebase/auth'

if (!firebase.apps.length) {
  firebase.initializeApp({
    apiKey: process.env.NEXT_PUBLIC_FIREBASE_API_KEY,
    authDomain: process.env.NEXT_PUBLIC_FIREBASE_AUTH_DOMAIN,
    projectId: process.env.NEXT_PUBLIC_FIREBASE_PROJECT_ID,
  })
}

export const googleAuthProvider = new firebase.auth.GoogleAuthProvider()
export const signInWithGoogle = async (): Promise<firebase.User | null> => {
  try {
    const response = await firebase.auth().signInWithPopup(googleAuthProvider)
    return response.user
  } catch (error) {
    return null
  }
}
