export interface PageDetailsInterface {
  meta: MetaInterface
}

interface MetaInterface {
  title: string
  description: string
  type: string
  image: string
}

interface PagesDetailsInterface {
  home: PageDetailsInterface
  jobs: PageDetailsInterface
  pricing: PageDetailsInterface
  addJob: PageDetailsInterface
  contact: PageDetailsInterface
}

const PAGE_CONSTANT_TEXTS: PagesDetailsInterface = {
  home: {
    meta: {
      title: 'Home - 0xCareer',
      description: '0xCareer - Meta Description',
      type: 'website',
      image: '',
    },
  },
  jobs: {
    meta: {
      title: 'Jobs - 0xCareer',
      description: '0xCareer - Meta Description',
      type: 'website',
      image: '',
    },
  },
  pricing: {
    meta: {
      title: 'Pricing - 0xCareer',
      description: '0xCareer - Meta Description',
      type: 'website',
      image: '',
    },
  },
  addJob: {
    meta: {
      title: 'Post your Job - 0xCareer',
      description: '0xCareer - Meta Description',
      type: 'website',
      image: '',
    },
  },
  contact: {
    meta: {
      title: 'Contact Us | 0xCareer',
      description: '0xCareer - Meta Description',
      type: 'website',
      image: '',
    },
  },
}

export default PAGE_CONSTANT_TEXTS
