import React, { FC, useState } from 'react'
import Link from 'next/link'
import { Container } from 'react-bootstrap'
import { GlobalContext } from 'context/globalContext'
import { useRouter } from 'next/router'
import toast from 'helpers/toast'
import { firstStepRegister } from 'services/webservice/api'
import { signInWithGoogle } from 'utils/auth/firebaseClient'

const SignupFooter: FC = () => {
  const router = useRouter()
  const [email, setEmail] = useState('') // For redirect to sign-up page

  const { isLoggedIn }: any = React.useContext(GlobalContext)

  const handleSubmitEmail = () => {
    if (email !== '') {
      localStorage.onBoardingDetails = JSON.stringify({ emailId: email })
      router.push('/signup')
    } else {
      toast.error('Please Enter Email Address')
    }
  }
  const handleSignupWithGoogle = async () => {
    const user = await signInWithGoogle()
    if (user?.email) {
      const IsUserExist = await firstStepRegister({ emailId: user.email })
      if (IsUserExist?.responseCode === 200) {
        const namesArray: any = user.displayName?.split(' ') // split the string into an array at the space character
        const userDetail = {
          emailId: user.email,
          firstName: namesArray[0],
          lastName: namesArray[1],
          provider: 'google',

        }
        localStorage.setItem('onBoardingDetails', JSON.stringify(userDetail))
        router.push('/submitOnboardingPage?step=1')
      } else {
        toast.error(IsUserExist.responseMessage)
      }
    }
  }
  return (
    <>
      {' '}
      {!isLoggedIn ? (
        <section className="best_job_find">
          <Container>
            <div className="d-md-flex justify-content-between flex-wrap">
              <div className="">
                <div>
                  <p className="text-white Space-Grotesk fw-400 fs-16px mb-0">
                    Best jobs in crypto in your inbox.
                  </p>
                  <p className="text-white Space-Grotesk fw-400 fs-16px opacity-70 mb-3 mb-md-0">
                    Every week. Sign Up now:
                  </p>
                </div>
              </div>

              <div className="">
                <div className="enter_input">
                  <input
                    type="text"
                    placeholder="Enter email to Sign up & get the best Job News"
                    className=" w-md-425px w-100 get_best_job sw-background-color-ffffff sw-font-size-s sw-text-color-000000
              sw-font-family-default sw-border-radius-m sw-padding-top-5xs sw-padding-bottom-5xs sw-border-style-solid sw-border-width-xs sw-border-color-000000
            sw-box-shadow-s sw-margin-left-5xs sw-display-inline-block sw-padding-left-5xs"
                    onChange={(e) => setEmail(e.target.value)}
                  />
                  <a className="best_job_btn" onClick={() => handleSubmitEmail()}>
                    {' '}
                    <img
                      src="/assets/images/Vector.png"
                      alt="logo"
                      id="right-arrow-icon"
                    />{' '}
                  </a>
                </div>
              </div>
              <div className="d-flex align-items-center">
                <div className="">
                  <p className=" Space-Grotesk fw-400 fs-18px mb-0 color-white  opacity-70 me-3">
                    Or <span className="bold-sign-text">Sign Up</span> with One
                    Click →{' '}
                  </p>
                </div>
                <div className="social_links d-flex flex-wrap">
                  <span className="me-2">
                    <img
                      src="/assets/images/google.png"
                      alt="logo"
                      className="img-fluid "
                      onClick={() => handleSignupWithGoogle()}
                    />
                  </span>
                  {/* <span className="me-2">
                    <a href="#">
                      <img
                        src="/assets/images/linkdien.png"
                        alt="logo"
                        className="img-fluid "
                      />
                    </a>
                  </span> */}
                </div>
              </div>
            </div>
          </Container>
        </section>
      ) : null}
    </>
  )
}

export default SignupFooter
